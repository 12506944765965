<template>
    <div>
      <img :src="require('../assets/images/Vencasa-Leaf-Top-Border-4K.webp')" />
      <div>
        <div class="container">
        <div class="col-12" v-html="inner"></div>
        </div>
      </div>
    </div>
</template>

<script>
import Quill from "quill";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      email: "",
      termsAndConditions: null,
      inner: "",
    };
  },
  computed: {
    ...mapGetters({
      configurations: "getConfigurations",
    }),
  },
  methods: {
    submit() {
      this.$store
        .dispatch("forgotPassword", { email: this.email })
        .then((res) => {
          if (res == true) this.$refs["email-modal"].show();
          else alert("Error! Please try again later");
        });
    },
  },
  async mounted() {
    if (_.isEmpty(this.configurations)) {
      await this.$store.dispatch("getConfigurations");
    }
    this.termsAndConditions = this.configurations.find((config) => {
      return config.key == "termsAndConditions";
    });
    let article = document.createElement("article");
    let quill = new Quill(article, { readOny: true });
    quill.setContents(this.termsAndConditions.val);
    console.log({ quill });
    this.inner = quill.root.innerHTML;
  },
};
</script>

<style scoped src="../assets/css/fontawesome-all.css"></style>
<style scoped src="../assets/css/fontawesome-5-all.min.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/styles.css"></style>

<style scoped>

div >>> p {
  margin: 0;
  white-space: break-spaces;
}

#login .login,
#register .login {
  width: 500px;
  padding-top: 0px;
}
.form-control {
  border-radius: 8px;
}
h2 {
  text-transform: inherit;
  font-size: 1.3em;
}

.margin-40{
  margin-left: 40px;
  margin-right: 40px;
}

p {
  font-weight: 500;
}
</style>
